<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Salario del Personal</span>
      </v-card-title>
      <v-divider />
      <v-card-text class="pb-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                label="Tipo de Pago"
                v-model="personal.id_payment"
                :items="payments"
                item-text="payment"
                item-value="id_payment"
                required
                outlined
                hide-details
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.payment }} - ${{ data.item.amount_hour }} Por
                  Hora
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.payment }} - ${{ data.item.amount_hour }} Por
                  Hora
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpdatePayment",
  data() {
    return {
      payments: {},
      isLoading: false,
      dialog: false,
    };
  },
  mounted() {
    this.getPayments();
  },
  props: { personal: Object, clean: { type: Function } },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getPayments: async function() {
      await this.$http.get(this.api + "payment/all").then(({ data }) => {
        this.payments = data;
      });
    },
    update: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "personal/payment", this.toFormData(this.personal))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    changeModal: function() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style></style>
