<template>
  <div>
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Documentos del Personal</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-container class="pb-0">
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Nombre"
                  required
                  outlined
                  hide-details
                  v-model="newDocument.name"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  label="Tipo de Documento"
                  :items="typeDocuments"
                  item-text="document_type"
                  item-value="id_document_type"
                  required
                  outlined
                  hide-details
                  v-model="newDocument.id_document_type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-file-input
                  accept="image/png, image/jpeg, application/pdf"
                  label="Archivo"
                  placeholder="Seleccione el archivo"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  required
                  outlined
                  show-size
                  hide-details
                  v-model="newDocument.file"
                ></v-file-input>
              </v-col>
              <v-col class="text-center" cols="12" sm="12">
                <v-btn color="primary" @click="add()" :loading="isLoading">
                  <v-icon left>mdi-plus</v-icon>
                  Agregar Documento
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <h3 class="black--text">Lista de documentos</h3>
                <template v-if="data.data.length > 0">
                  <v-chip
                    v-for="(item, index) in data.data"
                    :key="index"
                    class="ma-2"
                    color="primary"
                    text-color="white"
                    close
                    @click="clickChip(item.file)"
                    @click:close="
                      selectDocument(item);
                      modalDelete();
                    "
                  >
                    {{ item.name }} ({{ item.document_type }})
                  </v-chip>
                </template>
                <template v-if="!data.load && data.data.length == 0">
                  <p class="text-center mb-0">
                    No se han encontrado documentos
                  </p>
                </template>
                <template v-if="data.load">
                  <p class="text-center mb-0">
                    Cargando...
                  </p>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="changeModal()">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <p class="mb-0 mt-4">
            ¿Está seguro de eliminar este elemento?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDelete()">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteDocument()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpdateDocument",
  data() {
    return {
      data: { load: false, data: {} },
      typeDocuments: {},
      slcDocument: {},
      newDocument: {},
      isLoading: false,
      dialog: false,
      dialogDelete: false,
    };
  },
  props: { personal: Object, clean: { type: Function } },
  mounted() {
    this.getTypeDocuments();
  },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getTypeDocuments: async function() {
      await this.$http.get(this.api + "document_type/all").then(({ data }) => {
        this.typeDocuments = data;
      });
    },
    getDocuments: async function(id_user) {
      this.data.load = true;
      await this.$http
        .get(this.api + "document/all/" + id_user)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
    add: function() {
      this.isLoading = true;
      this.newDocument.id_user = this.personal.id_user;
      this.$http
        .post(this.api + "document", this.toFormData(this.newDocument))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanAdd();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    deleteDocument: function() {
      this.$http
        .delete(this.api + "document/" + this.slcDocument.id_document)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.closeDelete();
        });
    },
    selectDocument: function(item) {
      this.slcDocument = item;
    },
    cleanUpdate: function() {
      this.slcDocument = {};
      this.getDocuments(this.personal.id_user);
    },
    cleanAdd: function() {
      this.newDocument = {};
      this.getDocuments(this.personal.id_user);
    },
    modalDelete: function() {
      this.dialogDelete = true;
    },
    closeDelete: function() {
      this.dialogDelete = false;
    },
    clickChip: function(doc) {
      window.open(this.files + "document/" + doc, "_blank");
    },
    changeModal: function() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      files: "getDocs",
    }),
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
